import {React,useState,useEffect} from 'react'
import ReactPlayer from 'react-player'
import Content from '../Content.png'
import louis from '../image (2).png'
import Avatar from '../Avatar.png'
import Bookmark from '../Bookmark.png'
import Bookmarked from '../save.png'
import Comments from '../Comments.png'
import like from '../Like.png'
import liked from '../LikeColoured.png'
import share from '../Share.png'
import '../App.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import ReelsPlayer from './ReelsPlayer'

const ScrollFollowing = () => {
    const [isForyou,setIsForyou]=useState(false);
    const[isfollowed,setIsfollowed]=useState(false);
    const[isBookmark,setIsBookmark]=useState(false);
    const[isLiked,setIsLiked]=useState(false);
    // const[isshare,setIsshare]=useState(false);
    const[isComments,setIsComments]=useState(false);
    // const[videodata,setVideodata]=useState([]);
    const {pathname} = useLocation();
    useEffect(()=>{
        // console.log(pathname);
        console.log("this is scroll page");
        const value=pathname.includes('/scroll/foryou');
        // console.log(value); 
           if(value){
        // console.log("scroll is showing");
        setIsForyou(true);
        // console.log(isScrollpage);
      }
      else{
        setIsForyou(false);
      }
    },[pathname]);
    
 ///video api fetching
 
  return (
    <Link to='foryou' >
    <div className={`bg-cover relative bg-center bg-no-repeat h-full w-full text-white rounded-md`}>
       <ReelsPlayer/> 
      {isForyou||(<div className='upperpart absolute top-2 h-[30%] flex flex-row '>  
       <div className='w-[2.4rem] h-[2.4rem] logo m-2 mt-2 rounded-md'>
        <img className='' src={louis} alt="" />
       </div>
       <div className='flex flex-col mt-1'>
        <p>Louis Vuitton</p>
        <div className='flex flex-row gap-2'>
        <p>4.3</p>
        <h3 className='text-red-500'>342k</h3>Followers
        </div>
       </div>
      </div>)}
      <div className={`${isForyou?'h-[60%] right-2 top-[40%] lg:top-[40%]  justify-evenly  mb-2 ':'h-[70%] right-2 top-1/3 lg:top-1/2 justify-evenly'} likescomponet absolute  flex flex-col pr-3  items-end  text-sm text-center self-center`}>
        <div className='w-[2.5rem] h-[2.5rem] mt-4 relative'><img src={Avatar} alt="Avatar" />
        <div className='absolute bg-red-500 text-white rounded-2xl w-[1.5rem] h-[1.5rem] top-[1.7rem] right-[.4rem] text-center font-bold' onClick={()=>{setIsfollowed(!isfollowed)}}>{isfollowed?('✓'):('+')}</div>
        </div>
        <div className='flex flex-col scroolpagebuttons' onClick={()=>{
          setIsLiked(!isLiked)
        }}>{isLiked?(<img src={liked} alt="liked" />):(<img src={like} alt="like" />)}
        <p>250k</p>
        </div>
        <div className='flex flex-col scroolpagebuttons'><img src={Comments} alt="comments" />
        <p>100k</p>
        </div>
        <div className='flex flex-col scroolpagebuttons' onClick={()=>{setIsBookmark(!isBookmark)}}>{isBookmark?(<img src={Bookmarked} alt="Bookmark" />):
        (<img src={Bookmark} alt="Bookmark" />)}
        <p>89k</p>
        </div>
        <div className='flex flex-col scroolpagebuttons'><img src={share} alt="share" />
        <p className='text-sm'>139.5k</p>
        </div>
      </div>
    </div>
    
  </Link>
  )
}

export default ScrollFollowing
