import React from 'react'
import ProductCard from './ProductCard'
import logoimage from '../image (1).png'
const HomePageBrandProdcuts = () => {
  return (
    <div className='h-[29rem] w-full  '>
      <div className='bg-cover bg-center flex flex-col w-full h-full border border-gray-300 rounded-xl shadow-md bg-gradient-to-b from-[#ff9494] to-white'>
        <div className='flex flex-row justify-around mt-2 h-[10%]'> 
            <div className='leftside flex flex-row justify-center items-center mr-6'>
                <div className='border p-1 border-gray-300 rounded-xl w-12 h-12'> <img className='w-full h-full' src={logoimage} alt=''/></div>
                <div className=' p-1 flex flex-col'>
                    <p>Minimalist</p>
                    <p>2.7%</p>
                </div>
            </div>
            <div className='rightside flex flex-col '>
                <div className='followers'>361k Followers</div>
            <button className='bg-black text-white rounded-lg w-[60%] self-end'>Follow</button>
            </div>
        </div>
        <div className='grid grid-cols-2 w-full h-[90%] gap-2 items-center justify-items-center '>
        <ProductCard w="70%" h="80%"/>
        <ProductCard w="70%" h="80%"/>
        <ProductCard w="70%" h="80%"/>
        <ProductCard w="70%" h="80%"/>
        </div>
      </div>
    </div>
  )
}

export default HomePageBrandProdcuts
