import React, { useEffect } from 'react'
import live from '../LIVE.png'
import Search from '../Search.png'
import Inbox from "../Inbox.png"
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const UpperNavbar = () => {
  const [isScrollpage,setIsScrollpage]=useState(false);
  const [isForyou ,setIsForyou]=useState(false);
  const {pathname} =useLocation();
  useEffect(()=>{
    // console.log(pathname);
    console.log("this is scroll page");
    const value=pathname.includes('/scroll');
    console.log(value); 
       if(value){
    // console.log("scroll is showing");
    setIsScrollpage(true);
    // console.log(isScrollpage);
  }
  else{
    setIsScrollpage(false);
  }
},[pathname]);
  useEffect(()=>{
    // console.log(pathname);
    console.log("this is scroll page");
    const value=pathname.includes('/scroll/foryou');
    // console.log(value); 
       if(value){
    // console.log("scroll is showing");
    setIsForyou(true);
    // console.log(isScrollpage);
  }
  else{
    setIsForyou(false);
  }
},[pathname]);
  
  return (
    
    <div className={` flex flex-row text-white items-start justify-between ml-1  ${isForyou?'top-0 left-0 right-0 z-50  mt-2 ':'mt-2 w-full'}`}>
      <div>
        {isScrollpage?(<img src={live} alt='live'/>):(<div className='text-white ml-2'>scroll</div>)}
      </div>
      {isScrollpage?(
      <div className='text-white flex gap-2'>
      <p>Following</p>
      <Link to='foryou'> <p>For you</p></Link>
      </div>):null}
      <div className='flex mr-2'>
        <div>
        <img src={Inbox} alt='inbox'/>
        </div>
        <div>
            <img src={Search} alt="search" />
        </div>
      </div>
    </div>
  )
}

export default UpperNavbar
