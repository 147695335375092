import { FirebaseError, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {addDoc, getFirestore,collection} from "firebase/firestore";
import { createContext,useContext, useState, useEffect } from "react";
import { browserLocalPersistence, getAuth,signInWithEmailAndPassword, setPersistence,onAuthStateChanged} from "firebase/auth";
//settng up the firebase key

const firebaseConfig = {
    apiKey: process.env.REACT_APP_Api_Key,
    authDomain: process.env.REACT_APP_Auth_Domain,
    projectId: process.env.REACT_APP_Project_Id,
    storageBucket: process.env.REACT_APP_Storage_Bucket,
    messagingSenderId: process.env.REACT_APP_Messaging_SenderId,
    appId: process.env.REACT_APP_App_Id,
    measurementId: process.env.REACT_APP_Measurement_Id
  };
  const firebaseapp=initializeApp(firebaseConfig);
  // const analytics = getAnalytics(firebaseapp);
  const firestore=getFirestore(firebaseapp);

  const firebasecontext=createContext(firebaseapp);
  export default firebasecontext;
  export const useFirebase = () => useContext(firebasecontext);
  export const FirebaseProvider=(props)=>{
   
    const handlefirebasestorage=async (name,profileimg,post,following,followers,products)=>{
    try{ 
      const userDoc=await addDoc(collection(firestore, "UsersDetail"),{
      Username:name,
      UserFollowing:following,
      UserFollowers:followers,
      UserProfileimg:profileimg,
      UserPost:post,
      UserProducts:products
    })
  }
  catch (error) {
    console.error("Error storing user data:", error);
    throw error;
  }
    }
   //login function
   const[alwaysloggedin,setalwaysloggedin]=useState(true);
   const[isLoginpage,setIslogin]=useState(true);
   const auth = getAuth(firebaseapp);
   const  handlesignin=async (email,password)=>{
   await setPersistence(auth, browserLocalPersistence);
   return  await signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    if(user){
    setalwaysloggedin(false);
    console.log(user);
    }
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorMessage,errorCode);
  });
}
//
const [loading, setLoading] = useState(true);
useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      setalwaysloggedin(false);
    } else {
      setalwaysloggedin(true);
    }
    setLoading(false); // Loading is complete after checking auth state
  });

  return () => unsubscribe(); // Cleanup the listener on unmount
}, []);
  return (
    <firebasecontext.Provider value={{
     handlefirebasestorage,handlesignin,isLoginpage,alwaysloggedin
    }}>
      {props.children}
    </firebasecontext.Provider>
  )
  }