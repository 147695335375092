import React, { useDebugValue, useEffect, useState } from 'react';
import './App.css';
import HomPage from './component/HomPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import ProductCard from './component/ProductCard';
import { Provider } from 'react-redux';
import { store } from './app/store'
import Profile from './component/Profile';
import CheckoutPage from './component/CheckoutPage';
import ScrollLogin from './component/ScrollLogin';
import { useFirebase } from './FirebaseContext/FIrebaseContext';
import Discover from './component/Discover';
function App() {
  const firebase=useFirebase();
  console.log("alwaysloggedin",firebase.alwaysloggedin);
  // const [showpage,setShowpage]=useState(true);
  return (  
    <Provider store={store}>
      <BrowserRouter>
        <div className="
          App w-full min-h-screen flex justify-center items-center bg-black">
          {firebase.alwaysloggedin?(<ScrollLogin/>):(
          <div className="
            w-full sm:w-[300px] md:w-[350px] lg:w-[400px] h-full bg-white shadow-md
          ">
            <Routes>
              <Route path='/' element={<HomPage/>}/>
              <Route path='/Discover' element={<Discover/>}/>
              <Route path='/scroll' element={<HomPage/>}>
                <Route path='following' element={<HomPage/>}/>
                <Route path='foryou' element={<HomPage/>}/>
              </Route>
              <Route path='/live' element={<HomPage/>}/>
              <Route path='/profile/:id' element={<Profile/>}/>
              <Route path='/Checkout' element={<CheckoutPage/>}/>
            </Routes>
          </div>
          )}
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;