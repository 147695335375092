import React, { useState } from 'react'
import statusimage from '../Ellipse.png'
import { Link } from 'react-router-dom'

const Livetext=()=>{
    return(
        <div className='absolute top-[85%] left-[20%] ' >
            <div className='w-[3rem] h-[1rem] text-center bg-red-500 rounded-md text-sm flex justify-center items-center'><p className='text-white'>LIVE</p></div>
        </div>
    )
}

const StatusLive = () => {
  // const [isLive, setIsLive]=useState(false);
  return (
    <Link to="/live">
    <div className='w-[4.375rem] h-[4.375rem] border rounded-full border-red-500 relative p-[2px]'>
      <img src={statusimage} alt='statusimage'/> 
      <Livetext/>     
    </div>
    </Link>
  )
}

export default StatusLive
