import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";

const ReelsPlayer = () => {
  const [videos, setVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true)
  // Fetch video data from the API
  useEffect(() => {
    const fetchVideos = async () => {
      const apiUrl = "https://gist.githubusercontent.com/poudyalanil/ca84582cbeb4fc123a13290a586da925/raw/14a27bd0bcd0cd323b35ad79cf3b493dddf6216b/videos.json";
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error("Failed to fetch videos");
        const data = await response.json();
        setVideos(data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };
    
    fetchVideos();
  }, []);

  // Handle scroll to navigate between videos
  const handleScroll = (e) => {
    // console.log("scroll distance:",e.deltaY);
    // console.log("current index:",currentIndex);
    if (e.deltaY > 0 && currentIndex < videos.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else if (e.deltaY < 0 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1); 
    }
  };

  // Return loading state if videos are not fetched yet
  if (videos.length === 0) {
    return <div className="text-center text-white">Loading videos...</div>;
  }

  return (
    <div
      onWheel={handleScroll}
      className="h-full w-full flex justify-center items-center bg-black"
      onClick={()=>{setIsPlaying(!isPlaying)}}
    >
      {/* Video Player */}
      <ReactPlayer
        url={videos[currentIndex].videoUrl}
        playing={isPlaying}
        controls={false}
        loop
        width="100%"
        height="100%"
        className="react-player"
      />

      {/* <div className="absolute bottom-8 left-8 text-white">
        <h2 className="text-lg font-bold">{videos[currentIndex].title}</h2>
        <p>{videos[currentIndex].description}</p>
      </div> */}
    </div>
  );
};

export default ReelsPlayer;
