import React from 'react'
import { useState,useEffect } from 'react';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import UpperNavbar from './UpperNavbar';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import hotpricetag from '../Hot Price Tag.png';
import scroll from '../profilescroll.png';
import styles from '../styles.png';
import profilelive from '../profileicon.png';
import profileproduct from '../profileproducts.png'
import { 

  SearchIcon 
} from 'lucide-react';
import ProfileScroll from './ProfileScroll';
import { Button } from 'react-scroll';
// import { error } from 'console';
const Profile = () => {
    const [profiledetail,setIsprofiledetail]=useState({
      name: "RAHUL",
      brand: "SCROLL",
      content: "Social Media with Ecommerce",
      rating: 0,
      posts: 0,
      followers: 0,
      following: 0,
      products: 0
    })

   var {id}=useParams();
const Profilepagefetching = async ()=>{
  const url = `http://localhost:8080/profile/${id}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }
    const profilehtml=await response.json();
    // const maindata=await profilehtml.thsetProfile(html)});
    // setProfile(profilehtml);
    console.log("fetching is working");
    // const json = await response.json();
    console.log(profilehtml.userprofile);
     setIsprofiledetail(profilehtml.userprofile);
    // console.log(profile.name);
  } catch (error) {
    console.error(error.message);
    // setError('Failed to load profile');
  }
}



// arraofdata.forEach((val)=>{
//   console.log(val.images[0]);
// })


// Profilepagefetching()
useEffect(()=>{Profilepagefetching()},[id]);
  //   imagedata.forEach((val)=>{
  //   console.log(val.images[0]);
  // })
  return (
    <div className=' w-full sm:w-[300px] lg:w-[400px] h-screen        
      mx-auto         
      border 
      border-gray-500 
      rounded-md 
      flex 
      flex-col 
      bg-black 
      overflow-hidden'>
    <UpperNavbar/>
    <div className="w-full bg-black text-white relative overflow-hidden mx-1 ">
      {/* Profile Section Background */}
      <div className="relative">
        <img 
          src="/api/placeholder/392/200" 
          alt="Brand Poster" 
          className="w-full h-auto object-cover"
        />
      </div>

      {/* Profile Content */}
      <div className="relative mt-[-50px] px-2">
        {/* Profile Image */}
        <div className="w-24 h-24 rounded-full border-2 border-gray-700 bg-gray-300 mx-auto overflow-hidden mb-4">
          <img 
            src="/api/placeholder/96/96" 
            alt="Profile" 
            className="w-full h-full object-cover"
          />
        </div>

        {/* Profile Info */}
        <div className="mb-4">
          <div className="flex justify-between items-center">
            <div>
              <span className="block">{profiledetail.name}</span>
              <span className="text-gray-400">{profiledetail.brand}</span>
            </div>
            <span>{profiledetail.rating} ⭐</span>
          </div>
          <div className="text-gray-500 mt-2">{profiledetail.content}</div>
        </div>

        {/* Stats */}
        <div className="flex justify-between px-5 py-4">
          {[
            { label: 'Posts', value: profiledetail.posts },
            { label: 'Followers', value: profiledetail.followers },
            { label: 'Following', value: profiledetail.following },
            { label: 'Products', value: profiledetail.products }
          ].map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-xl">{stat.value}</div>
              <div className="text-gray-500">{stat.label}</div>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex gap-4 px-5 mb-5">
          <button className="flex-1 bg-pink-600 text-white py-2 rounded-md">
            Follow
          </button>
          <button className="flex-1 bg-white text-black py-2 rounded-md">
            Message
          </button>
        </div>

        {/* Search Bar */}
        <div className="mx-5 bg-gray-800 rounded-lg flex items-center px-4 py-3 mb-4">
          <SearchIcon className="text-white mr-2" />
          <input 
            type="text" 
            placeholder="Find in store....." 
            className="bg-transparent text-white w-full border-none focus:outline-none"
          />
        </div>

        {/* Category Icons */}
        <div className="flex justify-around  py-4 border-b border-gray-800">
        <Button>  <img className='w-10 h-8' src={scroll} alt="scrollicon" /></Button>
        <Button>   <img className='w-8 h-8' src={styles} alt="stylesicon" /></Button>
        <Button>   <img className='w-8 h-8' src={profileproduct} alt="producticon" /></Button>
        <Button>  <img className='w-8 h-8' src={profilelive} alt="liveicon" /></Button>
        <Button>   <img className='w-8 h-8' src={hotpricetag} alt="hotpricetagicon" /></Button>
        </div>

        {/* Image Grid */}
        <ProfileScroll/>
      </div>
    </div>
    
    <Navbar/>
    </div>
  )
}

export default Profile
