import React, { useEffect,useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
// import { useEffect } from 'react';


const ProfileScroll = () => {
    const [imagedata,setImagedata]=useState([]);
const [page, setPage] = useState(1);
 const [hasMore, setHasMore] = useState(true);
 const [totalProducts, setTotalProducts] = useState(0);
//data fetching from dummy api 
const fetchproductimage=async ()=>{
    try{
      const url = `https://dummyjson.com/products?limit=9&skip=${(page - 1) * 9}`;
   const response = await fetch(url);
   if(!response.ok){
    throw new Error('Response status');
   }
   const imagedatas=await response.json();
  //  console.log(imagedatas.products);
  const arraofdata=imagedatas.products;
  setImagedata(prevImages => [...prevImages, ...arraofdata]);
console.log("arryofdata:",arraofdata.length);
if (page === 1) {
    setTotalProducts(imagedatas.total);
}
// if (imagedata.length + arraofdata.length >= totalProducts) {
//   setHasMore(false);
// }
setPage(prevPage => prevPage + 1);
  }catch(error){
    console.log("error occured:",error);
    setHasMore(false);
  }
}
console.log(totalProducts);
  useEffect(()=>{fetchproductimage()},[]);
  return (
    <div 
            id="scrollableDiv"
            className="h-[300px] overflow-y-scroll"
          >
            <InfiniteScroll
              dataLength={imagedata.length}
              next={fetchproductimage}
              hasMore={hasMore}
              loader={<h4 className="text-white text-center my-4">Loading more...</h4>}
              endMessage={
                <p className="text-white text-center my-4">
                  No more images to load
                </p>
              }
              scrollableTarget="scrollableDiv"
            >
              <div className="grid grid-cols-2 gap-1 bg-black pb-1">
                {imagedata.map((product, index) => (
                  <div 
                    key={product.id} 
                    className="aspect-square bg-gray-800 hover:opacity-75 transition"
                  >
                    <img 
                      src={product.images[0]} 
                      alt={product.title} 
                      className="w-full h-full object-cover"
                    />
                    <div className='w-[100%] overflow-hidden' >{product.title}</div>
                    <div>Price:-${product.price}</div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
  )
}

export default ProfileScroll
