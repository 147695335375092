import React from 'react'
import search from '../Search.png'
import StatusLive from './StatusLive'
import ScrollFollowing from './ScrollFollowing'
import Scrollpage from './Scrollpage'
import { useEffect, useState } from 'react'
import Navbar from './Navbar'

const Discover = () => {
  const [productfetch, setProductimage] = useState([])
  const[isSearchbaractive, setIssearchbaractive]=useState(false);
  const fetchproductimage = async () => {
    try {
      const url = `https://dummyjson.com/products?limit=10&page=2`
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error('Response status')
      }
      const imagedatas = await response.json()
      const arraofdata = imagedatas.products
      setProductimage(arraofdata)
    } catch (error) {
      console.log('error occured:', error)
    }
  }
  useEffect(() => {
    fetchproductimage()
  }, [])

  return (
    <div className="discover w-full sm:w-[300px] md:w-[350px] lg:w-[400px] h-screen mx-auto border border-gray-500 rounded-md flex flex-col bg-black overflow-hidden justify-between">
      <div className="upperpart flex flex-col bg-black h-[12%]">
        <div className="flex flex-row w-[90%] bg-gray-700 border border-black m-1 rounded-md px-2 py-1 self-center">
          <img src={search} alt="search.png" className="h-5 w-5 mr-2 " />
          <input
            type="text"
            placeholder="Search anything...."
            className="bg-gray-700 w-full focus:outline-none"
            onClick={()=>setIssearchbaractive(true)}
          />
        </div>
        <div className="flex flex-row justify-between items-center ml-3 mr-3">
          <div className="border p-1 pl-1 pr-1 m-1 rounded-md text-white border-black text-sm bg-gray-700 cursor-pointer">{isSearchbaractive?'Products':'#Trending'}</div>
          <div className="border p-1 pl-1 pr-1 m-1 rounded-md text-white border-black text-sm bg-gray-700 cursor-pointer">{isSearchbaractive?'Brands/Creators':'#Hot Deals'}</div>
          <div className="border p-1  pl-1 pr-1 m-1 rounded-md text-white  bg-gray-700 text-sm border-black cursor-pointer">{isSearchbaractive?'Lives':"What's New"}</div>
        </div>
      </div>
      {isSearchbaractive?(<div className='h-full border-red-400'> </div>):(<div className="h-[70%] overflow-y-auto [-ms-overflow-style:none] 
  [scrollbar-width:none] 
  [&::-webkit-scrollbar]:hidden ">
        {[...Array(2)].map((_,index)=>(<div className='h-fit mt-2' key={index}>
        <div className=' grid grid-cols-3 gap-1 justify-items-center'>
          {[...Array(6)].map((_,index)=>(
            <div key={index} className='border  border-red-400 h-24 w-24'></div>
          ))}
        </div>
        <div className="flex flex-col ">
          <p className="text-white ml-3 mb-3 mt-3">#Top Creators</p>
          <div className="flex flex-row justify-evenly">
            <StatusLive />
            <StatusLive />
            <StatusLive />
            <StatusLive />
          </div>
        </div>
        <div className="flex flex-col mt-3">
          <p className="text-white ml-3 mb-3">#Top Scrolls</p>
          <div className="flex flex-row w-full justify-around gap-2 px-2 overflow-x-scroll [-ms-overflow-style:none] 
  [scrollbar-width:none] 
  [&::-webkit-scrollbar]:hidden ">
            <div className="flex flex-row">
              {productfetch.slice(0, 3).map((product, index) => (
                <Scrollpage key={product.id} imageUrl={product.thumbnail} />
              ))}
            </div>
            <div className="flex flex-row">
              {productfetch.slice(3).map((product, index) => (
                <div
                  key={product.id}
                  className="min-w-[120px] transition-opacity duration-300"
                >
                  <Scrollpage imageUrl={product.thumbnail} />
                </div>
              ))}
            </div>
          </div>
        </div>
        </div>))}
      </div>)}
      <Navbar />
    </div>
  )
}

export default Discover