import React, { useState, useEffect } from 'react'
import Navbar from './Navbar'
import UpperNavbar from './UpperNavbar'
import StatusLive from './StatusLive'
import Scrollpage from './Scrollpage'
import BrandPage from './BrandPage'
import { useLocation } from 'react-router-dom'
import ScrollFollowing from './ScrollFollowing'
import { Link } from 'react-router-dom'
import HomePageBrandProdcuts from './HomePageBrandProdcuts'

const HomPage = () => {
  const [isScrollpage, setIsScrollpage] = useState(false);
  const [isForyou, setIsForyou] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [productfetch,setProductimage]=useState([]);
  const { pathname } = useLocation();

  // Effect for scroll page detection
  useEffect(() => {
    const scrollValue = pathname.includes('/scroll');
    const profileValue = pathname.includes('/profile');

    setIsProfilePage(profileValue);
    setIsScrollpage(scrollValue);
  }, [pathname]);

  // Effect for following page detection
  useEffect(() => {
    const followingValue = pathname.includes('/scroll/foryou');
    setIsForyou(followingValue);
  }, [pathname]);
 
  const fetchproductimage=async ()=>{
    try{
      const url = `https://dummyjson.com/products?limit=10&page=2`;
   const response = await fetch(url);
   if(!response.ok){
    throw new Error('Response status');
   }
   const imagedatas=await response.json();
  //  console.log(imagedatas.products);
  const arraofdata=imagedatas.products;
  setProductimage(arraofdata);
// console.log("arryofdata:",arraofdata.length);
}catch(error){
    console.log("error occured:",error);
  }
}
useEffect(()=>{fetchproductimage()},[])
// productfetch.forEach((eve)=>{
//   console.log(eve.thumbanil);
// });
  return (
    <div className='
      w-full         
      sm:w-[300px]    
      md:w-[350px]
      lg:w-[400px]
      h-screen
      mx-auto
      border 
      border-gray-500 
      rounded-md 
      flex 
      flex-col 
      bg-black 
      overflow-hidden  
    '>
      <UpperNavbar/>
      
      <div className='
        flex-grow 
        overflow-y-auto  
        w-full
         [-ms-overflow-style:none] 
  [scrollbar-width:none] 
  [&::-webkit-scrollbar]:hidden 
      '>
        {!isForyou && (
          <div className='
            livesection 
            flex 
            flex-row 
            justify-around 
            items-start 
            p-2 
          '>
            {[...Array(4)].map((_, index) => (
              <StatusLive key={index}/>
            ))}
          </div>
        )}
        
        {/* Conditional Rendering Based on Route */}
        {isScrollpage ? (
          <div className={`
            w-full 
            ${isForyou ? 'h-full' : 'h-[26.25rem]'} 
            text-white
          `}> 
            <ScrollFollowing/>
          </div>
        ) : (
          <div className='flex flex-col w-full'>
            {/* Trending Section */}
            
            <div className='flex flex-col h-fit w-full items-start p-2'>
              <p className='text-center ml-2 text-white mb-2'>Trending</p>
                <div className='
                flex flex-row w-full justify-around gap-2 px-2  overflow-x-scroll  [-ms-overflow-style:none] 
  [scrollbar-width:none] 
  [&::-webkit-scrollbar]:hidden '>
                  <div className='flex flex-row'>
                    {productfetch.slice(0, 3).map((product, index) => (
                      <Scrollpage
                        key={product.id}
                        imageUrl={product.thumbnail}
                      // You can pass other product details as props if needed
                      />
                    ))}
                  </div>
                  <div className='flex flex-row'>
                    {productfetch.slice(3).map((product, index) => (
                      <div
                        key={product.id}
                        className='
                      min-w-[120px] 
                      transition-opacity 
                      duration-300
                    '
                      >
                        <Scrollpage
                          imageUrl={product.thumbnail}
                        // You can pass other product details as props if needed
                        />
                      </div>
                    ))}
                  </div>
                </div>
            </div>

            {/* Brand Page */}
            <div className='flex-grow p-2'>
              <BrandPage/>
            </div>
            <div className='flex-gro p-2'>
            <HomePageBrandProdcuts/>
            </div>
          </div>
        )}
      </div>
      
      <Navbar/>
    </div>
  )
}

export default HomPage