import React, { useState } from 'react';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import { useNavigate } from 'react-router-dom';
// import firebase
// import e from 'cors';
// const 
const ScrollLogin = () => {
  const [formdiv,setFormdiv]=useState(false);
  const [formdata,setformdata]=useState({
    email:"",
    password:"",
  });
  const firebase=useFirebase();
  const Navigate=useNavigate();
  const handlesigninwithuser=(e)=>{
    e.preventDefault()
    if(!formdata.email){
      console.log("enter the emai")
    }
    if(!formdata.password){
      console.log("enter the password")
    }
    else{
      console.log("yes login is working")
      firebase.handlesignin(formdata.email,formdata.password);
      Navigate('/');
    }
  }
  const handleChange = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
};
  return (
    <div className="bg-black  sm:w-[300px]
            md:w-[350px]  
            lg:w-[400px]
            h-full  flex flex-col items-center px-6">
      <div className="mt-32 mb-4 flex flex-col items-center">
        <div className="text-white text-4xl font-bold mb-2">
          <svg width="60" height="60" viewBox="0 0 60 60" className="mb-2">
            <circle cx="30" cy="30" r="30" fill="black"/>
            <path
              d="M20 35L30 15L40 35"
              stroke="white"
              strokeWidth="3"
              fill="none"
            />
            <path
              d="M15 28L45 28"
              stroke="#FFD700"
              strokeWidth="2"
              transform="rotate(-10, 30, 28)"
            />
            <text
              x="30"
              y="45"
              textAnchor="middle"
              fill="white"
              fontSize="12"
              fontWeight="bold"
            >
              SCROLL
            </text>
          </svg>
        </div>
        <h1 className="text-white text-3xl font-bold mb-4">Log in to Scroll</h1>
        <p className="text-gray-400 text-center text-sm mb-8">
          Manage your account, check notifications, comment and experience a new way to shop
        </p>
      </div>
      <div className='logindetails h-full w-full'>
      {/* Login Options */}
    
      {formdiv?(
        <form  className='w-full bg-gray-800 text-white py-3 px-4 rounded-lg mb-4 flex items-center  flex-col gap-1 '>
          {/* <input type="text" className='username rounded-md' name='username' placeholder='Name'/> */}
       <label htmlFor="email">Email:<input type="text" value={formdata.email} className='email rounded-md border border-black p-1 bg-gray-800' name='email' placeholder='Email' onChange={handleChange}/></label>
        <label htmlFor="password">Password:<input type="text" value={formdata.password} className='passwor border border-black p-1 rounded-md bg-gray-800' name='password' placeholder='Password' onChange={handleChange}/></label> 
          <button className='border mt-2 p-1 rounded-md' type="submit" onClick={handlesigninwithuser}>Login</button>
        </form>
      ):(<button className="w-full bg-gray-800 text-white py-3 px-4 rounded-lg mb-4 flex items-center" onClick={()=>{setFormdiv(true)}}>
        <svg className="w-6 h-6 mr-3" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          />
        </svg>
        Use phone / email / username
      </button>)
    }
      <button className="w-full bg-gray-800 text-white py-3 px-4 rounded-lg mb-4 flex items-center">
        <svg className="w-6 h-6 mr-3" viewBox="0 0 24 24">
          <path
            fill="#4285F4"
            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          />
          <path
            fill="#34A853"
            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          />
          <path
            fill="#FBBC05"
            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          />
          <path
            fill="#EA4335"
            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          />
        </svg>
        Continue with Google
      </button>

      <button className="w-full bg-gray-800 text-white py-3 px-4 rounded-lg mb-4 flex items-center">
        <svg className="w-6 h-6 mr-3" viewBox="0 0 24 24" fill="#1877F2">
          <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
        </svg>
        Continue with Facebook
      </button>
      </div>
      {/* Terms and Privacy */}
      <div className="mt-auto text-center text-sm text-gray-400">
        <p>
          By continuing, you agree to our{' '}
          <span className="text-white">Term of services</span>
        </p>
        <p>
          and acknowledge that you read our{' '}
          <span className="text-white">Privacy Policy</span>
        </p>
        <p className="mb-8">to learn how we collect and use your data .</p>
      </div>
      <div className="w-full bg-gray-900 rounded-lg py-4 mb-8 flex justify-center items-center">
        <span className="text-gray-400">Don't have an account? </span>
        <span className="text-pink-500 ml-1">Sign up</span>
      </div>
    </div>
  );
};

export default ScrollLogin;
