import React, { useState,useEffect } from 'react'
import homebar from '../Union.png'
import discover from '../Discover.png'
import live from '../LIVE.png'
import profile from '../myaccount.png'
import scroll from '../scroll.png'
import { Link } from 'react-router-dom'
// import {  useLocation } from 'react-router-dom'
// import  from '../Union.png'
import '../App.css'

const Navbar = () => {
  // const [isScrollpage,setIsScrollpage]=useState(false);
  // const handlescroll=()=>{
  //   setIsScrollpage(true);
  // 
  
  // }},[param])
  return (
    <div className='border border-gray-500 w-[full] h-[12%]  bg-black flex flex-row text-white items-center rounded-b-md justify-around pt-2'>
     <Link to='/' >
     <button className='navbarbuttons'>
        <div className='icon'><img className='w-6 h-6' src={homebar} alt='homebar'/></div>
       <p className='text-[0.8rem]'> Home</p> 
    </button>
    </Link>
    <Link to='/Discover' >
     <button className='navbarbuttons'>
     <div className='icon'><img className='w-6 h-6' src={discover} alt='discover'/></div>
        <p className='text-[0.8rem]'>Discover</p>
        </button>
        </Link>
      <Link to='/scroll' >
      <button className='navbarbuttons' >
     <div className='icon'><img className='w-6 h-6' src={scroll} alt='scroll'/></div>
        <p className='text-[0.8rem]'>Scroll</p>
      </button>
      </Link> 
      <Link to='/live' >
     <button className='navbarbuttons'>
     <div className='icon'><img className='w-6 h-6' src={live} alt='live'/></div>

      <p className='text-[0.8rem]'>Live</p>  
        </button>
</Link>
<Link to='/profile/id' >
     <button className='navbarbuttons'>
     <div className='icon'><img className='w-6 h-6' src={profile} alt='profile'/></div>
       <p className='text-[0.8rem]'>Profile</p> 
        </button>
        </Link>
    </div>
  )
}


export default Navbar
