// import { url } from 'inspector'
import React, { useState } from 'react'
import ProductCard from './ProductCard'
import brandImage from '../brandimage.png';
import logoimage from '../image (1).png'
import { Link } from 'react-router-dom';
const BrandPage = () => {
  const [brandUrl,setBrandUrl]=useState('minimilist');
  return (
    <div className='h-[29rem] w-full '>
      <div className='bg-cover bg-center flex flex-col justify-between w-full h-full border border-gray-300 rounded-xl shadow-md' style={{ backgroundImage: `url(${brandImage})` }}>
        <Link to={`/profile/${brandUrl}`}>
        <div className='flex flex-row justify-around mt-2'> 
            <div className='leftside flex flex-row justify-center items-center mr-6'>
                <div className='border p-1 border-gray-300 rounded-xl w-12 h-12'> <img className='w-full h-full' src={logoimage} alt=''/></div>
                <div className=' p-1 flex flex-col'>
                    <p>Minimalist</p>
                    <p>2.7%</p>
                </div>
            </div>
            <div className='rightside flex flex-col'>
                <div className='followers'>361k Followers</div>
            <button className='bg-black text-white rounded-lg w-[60%] self-end'>Follow</button>
            </div>
        </div>
        </Link>
        <div className='flex flex-row justify-around gap-1'>
        <ProductCard w="25%" h="75%"/>
        <ProductCard w="25%" h="75%"/>
        <ProductCard w="25%" h="75%"/>
        </div>
      </div>
    </div>
  )
}

export default BrandPage
