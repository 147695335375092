import React from 'react'
import product from '../productimage.png'
// import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
const ProductCard = ({w,h}) => {
  const Navigate=useNavigate();
  const gotoCheckoutpage=()=>{
    Navigate('/Checkout')
  }
  return (
   
    <div className={`productCard w-[${w}] h-[${h}] flex flex-col rounded-md`}  onClick={gotoCheckoutpage}>
        <div className='w-full h-[70%]'><img className='w-full h-[100%]' src={product} alt='prodcutimage'/></div>
        <div className='description text-sm'><p>Hii this is my product</p></div>
        <div className='price text-blue-300'><h3>360k</h3></div>
    </div>
  )
}

export default ProductCard
